
import React, { useRef } from 'react'
import { Environment, useGLTF } from '@react-three/drei'
import { useControls } from 'leva'

export default function Osomelogo({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/osomeLogo-transformed.glb')
  const materialProps = useControls({
    thickness: { value: 5, min: 0, max: 20 },
    roughness: { value: 1, min: 0, max: 1, step: 0.1 },
    metalness: { value: 1, min: 0, max: 1, step: 0.1 },
    clearcoat: { value: 1, min: 0, max: 1, step: 0.1 },
    clearcoatRoughness: { value: 0, min: 0, max: 1, step: 0.1 },
    transmission: { value: 1, min: 0.9, max: 1, step: 0.01 },
    ior: { value: 1.25, min: 1, max: 2.3, step: 0.05 },
    envMapIntensity: { value: 1, min: 0, max: 100, step: 1 },
    color: '#ffffff',
    attenuationTint: '#ffe79e',
    attenuationDistance: { value: 0, min: 0, max: 1 }
  })
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.osome3.geometry} material={materials.osome3} rotation={[Math.PI / 2, 0, 0]} scale={3} >
        <meshPhysicalMaterial {...materialProps} />
      </mesh>
      <Environment preset="city" />

    </group>
  )
}

useGLTF.preload('/osomeLogo-transformed.glb')
