
import React, { useRef } from 'react'
import { useGLTF, MeshDistortMaterial } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { useControls } from 'leva'


export default function Necklace({ name, ...props }) {
  const ref = useRef()
  const group = useRef()
  const { nodes, materials } = useGLTF('/necklace-transformed.glb')
  const materialProps = useControls({
    thickness: { value: 5, min: 0, max: 20 },
    roughness: { value: 1, min: 0, max: 1, step: 0.1 },
    metalness: { value: 1, min: 0, max: 1, step: 0.1 },
    clearcoat: { value: 1, min: 0, max: 1, step: 0.1 },
    clearcoatRoughness: { value: 0, min: 0, max: 1, step: 0.1 },
    transmission: { value: 1, min: 0.9, max: 1, step: 0.01 },
    ior: { value: 1.25, min: 1, max: 2.3, step: 0.05 },
    envMapIntensity: { value: 1, min: 0, max: 100, step: 1 },
    color: '#ffffff',
    attenuationTint: '#ffe79e',
    attenuationDistance: { value: 0, min: 0, max: 1 }
  })
  useFrame(() => {
    ref.current.rotation.x = ref.current.rotation.y += 0.01
  })
  return (
    <group ref={group} {...props} dispose={null}>
      <group ref={ref} position={[0, 8.18, 0]}>
        <mesh geometry={nodes.bracelet_low_1.geometry} material={materials.cube1} material-color="#ffaf30">
          <meshPhysicalMaterial {...materialProps} />
        </mesh>
        <mesh geometry={nodes.bracelet_low_2.geometry} material={materials.cube2} material-color="#ffaf30">
         <meshPhysicalMaterial {...materialProps} />
        </mesh>
        <mesh geometry={nodes.bracelet_low_3.geometry} material={materials.cube3} material-color="#ffaf30">
          <meshPhysicalMaterial {...materialProps} />
        </mesh>
        <mesh geometry={nodes.bracelet_low_4.geometry} material={materials.cube4} material-color="#ffaf30">
         <meshPhysicalMaterial {...materialProps} />
        </mesh>
      </group>
    </group>
  )
}

useGLTF.preload('/necklace-transformed.glb')
