import * as THREE from 'three'
import { useRef } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { useIntersect, Image, ScrollControls, Scroll } from '@react-three/drei'
import Necklace from './necklace/Necklace'
import Osomelogo from './Osome'

function Item({ url, scale, ...props }) {
  const visible = useRef(false)
  const ref = useIntersect((isVisible) => (visible.current = isVisible))
  const { height } = useThree((state) => state.viewport)
  useFrame((state, delta) => {
    ref.current.position.y = THREE.MathUtils.damp(ref.current.position.y, visible.current ? 0 : -height / 2 + 1, 4, delta)
    ref.current.material.zoom = THREE.MathUtils.damp(ref.current.material.zoom, visible.current ? 1 : 1.5, 4, delta)
  })
  return (
    <group {...props}>
      <Image ref={ref} scale={scale} url={url} />
    </group>
  )
}

function Items() {
  const { width: w, height: h } = useThree((state) => state.viewport)
  return (
    <Scroll>
      <hemisphereLight color="#ffffff" groundColor="#b9b9b9" position={[-7, 25, 13]} intensity={1} />
      <pointLight position={[100, 100, 100]} intensity={1} />
      <Item url="/OpenSeaQrCode.png" scale={[3, 3, 1]} position={[-w / 15, -h * 2, 0]} />
      <Necklace scale={0.2} position={[-8, 0, -1]}/>
      <Necklace scale={0.1} position={[6, -2, -1]}/>
      <Osomelogo scale={0.3} position={[w / 30, 0, 0]}/>
      <Necklace scale={0.2} position={[8, -20, -1]}/>

    </Scroll>
  )
}

export default function App() {
  return (
    <Canvas orthographic camera={{ zoom: 80 }} gl={{ alpha: false, antialias: false, stencil: false, depth: false }} dpr={[1, 1.5]}>
      <color attach="background" args={['#a068b1']} />
      <ScrollControls damping={6} pages={3}>
        <Items />
        <Scroll html style={{ width: '100%' }}>
          <h1 style={{ position: 'absolute', top: '100vh', left: '4vw' }}>chrome.</h1>
          <h2 style={{ position: 'absolute', top: '100vh', left: '52vw' }}>osome</h2>
          <h1 style={{ position: 'absolute', top: '200vh', right: '10vw' }}>
            coming
            <br />
            soon.
          </h1>
        </Scroll>
      </ScrollControls>
    </Canvas>
  )
}